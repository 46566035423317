<template>
  <div id="main-content"
       class="main-content-normal"
       :class="{'main-content-bigger':$store.state.mainBig}">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h1>
              <!-- 电站信息 -->
              {{ $t("sidebar.menu.2133314RMFX7WB6E") }}
            </h1>
          </div>
        </div>
      </div>

      <div class="row clearfix mt-4">
        <div class="col-xl-12">
          <div class="card">

            <div class="card-header">
              <div class="row clearfix">
                <div class="col-lg-12 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-6 col-md-auto">
                      <select-ps-id-view :cls="'col-12'"
                                         @psIdChange="handlePsIdChange" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <el-row :gutter="12">
                <el-col :span="12">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.PlantName") }}
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-warehouse small text-dark-35"></i></span>
                      </div>
                      <input type="name"
                             v-model="psInfo.psName"
                             class="form-control"
                             v-validate="'required'"
                             name="PlantName"
                             value="比企郡小川町大字青山字城北125"
                             disabled />
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.PlantType") }}
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-tasks small text-dark-55"></i></span>
                      </div>
                      <select type="name"
                              v-model="psInfo.psTypeId"
                              class="form-control"
                              v-validate="'required'"
                              name="PlantType"
                              disabled>
                        <template v-for="(item) in psTypeList">
                          <option :key="item.psTypeId"
                                  :value="item.psTypeId">{{item.psTypeName}}</option>
                        </template>
                      </select>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.PlantID") }}
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-barcode-read small text-dark-55"></i></span>
                      </div>
                      <input type="name"
                             v-model="psInfo.psId"
                             class="form-control"
                             v-validate="'required'"
                             name="PlantID"
                             value="EMSS2130007C662"
                             disabled />
                    </div>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="12">
                <el-col :span="8">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.PVcapacity") }} (kWh)
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-sliders-h-square small text-dark-55"></i></span>
                      </div>
                      <input type="name"
                             v-model="psInfo.dcdcCapacity"
                             class="form-control"
                             v-validate="'required'"
                             name="PVcapacity"
                             value=""
                             disabled />
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.DCDCcapacity") }} (kWh)
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-cabinet-filing small text-dark-55"></i></span>
                      </div>
                      <input type="name"
                             v-model="psInfo.dcdcCapacity"
                             class="form-control"
                             v-validate="'required'"
                             name="DCDCcapacity"
                             value=""
                             disabled />
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.BatteryCapacity") }} (kWh)
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-battery-bolt small text-dark-35"></i></span>
                      </div>
                      <input type="name"
                             v-model="psInfo.bmsCapacity"
                             class="form-control"
                             v-validate="'required'"
                             name="BatteryCapacity"
                             value=""
                             disabled />
                    </div>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="12">
                <el-col :span="18">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.Address") }}
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-map-marked-alt small text-dark-35"></i></span>
                      </div>
                      <input type="name"
                             v-model="psInfo.psAddress"
                             class="form-control"
                             v-validate="'required'"
                             name="Address"
                             value="比企郡小川町大字青山字城北125"
                             disabled />
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="form-group c_form_group disabled">
                    <div class="input-title">
                      <span class="IconAsterisk"></span> {{ $t("EditPlantInfo.TimeZone") }}
                    </div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-globe small text-dark-35"></i></span>
                      </div>
                      <select v-model="psInfo.psTimezone"
                              type="name"
                              class="form-control"
                              v-validate="'required'"
                              name="TimeZone"
                              disabled>
                        <template v-for="(item) in psTimezoneList">
                          <option :key="item.timezoneId"
                                  :value="item.timezoneId">{{item.timezoneName}}</option>
                        </template>
                      </select>
                    </div>
                  </div>
                </el-col>

              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SelectPsIdView from '../components/HistoryView/SelectPsId.vue'

export default {
  name: 'psinfo',
  components: {
    SelectPsIdView
  },
  data () {
    return {
      languageId: null,
      selPsId: null,
      selPsTypeName: null,
      psTypeList: [],
      psTimezoneList: [],
      psInfo: {
        psId: null,
        psName: null,
        dcdcCapacity: null,
        bmsCapacity: null,
        psTypeId: null,
        psTypeName: null,
        psAddress: null,
        psTimezone: null,
        psTimezoneName: null,
      },
    }
  },
  watch: {
    '$store.state.languageId': function () {
        this.getLanguagePsTypeId(this.psTypeList);
        this.getLanguageTimeZoneId(this.psTimezoneList);
    }
  },
  created () {
    this.getPsTypeList();
    this.getTimeZoneList();
  },
  mounted () {

  },
  methods: {
    //电站切换
    handlePsIdChange (psBuilddate, psId, psName, psListDateTime) {
      //window.sessionStorage.setItem("psName", psName);
      //this.getQuickDeviceType();
      this.selPsId = psId;
      this.getPsInfoById();
      this.$emit("parentPsId");
    },
    //获取电站列表
    getPsTypeList () {
      var _this = this;
      _this.$http.get('/service-base/psType/getPsTypes').then(function (ret) {
        if (ret.status == 200 && ret.data.data != null) {
          _this.psTypeList = _this.getLanguagePsTypeId(ret.data.data)
        }
      });
    },
    //获取时区列表
    getTimeZoneList () {
      var _this = this;
      _this.$http.get('/service-base/timezone/getTimezones').then(function (ret) {
        if (ret.status == 200 && ret.data.data != null) {
          _this.psTimezoneList = _this.getLanguageTimeZoneId(ret.data.data)
        }
      });
    },
    //获取电站信息
    getPsInfoById () {
      var _this = this;
      _this.$http.get('/service-psinfo/psinfo/getPsInfoSmartomById?psId=' + _this.selPsId).then(function (ret) {
        if (ret.status == 200 && ret.data.data != null) {
          _this.psInfo = ret.data.data
        }
      });
    },
    getLanguageId(){
        let locale = this.$validator.locale
        if (this.$store.state.languageId == null) {
          this.$store.state.languageId = locale
        }
        switch (this.$store.state.languageId) {
          case "zh_CN":
            this.languageId = 1
            break;
          case "ja":
            this.languageId = 2
            break
          case "en":
            this.languageId = 3
            break
          default:
            this.languageId = 2
        }
    },
    //电站类型多语言
    getLanguagePsTypeId (psTypeData) {
      for (var i = 0; i < psTypeData.length; i++) {
        switch (psTypeData[i]["psTypeId"]) {
          case 1:
            psTypeData[i]["psTypeName"] = this.$t("home.TypeSelf")
            break
          case 2:
            psTypeData[i]["psTypeName"] = this.$t("home.TypeLoad")
            break
          case 3:
            psTypeData[i]["psTypeName"] = this.$t("home.TypePeak")
            break
          case 4:
            psTypeData[i]["psTypeName"] = this.$t("home.TypePpv")
            break
        }
      }
      return psTypeData
    },
    //时区多语言
    getLanguageTimeZoneId (timeZoneData) {
      for (var i = 0; i < timeZoneData.length; i++) {
        switch (timeZoneData[i]["timezoneId"]) {
          case 2:
            timeZoneData[i]["timezoneName"] = this.$t("TimeZoneLists."+timeZoneData[i]["timezoneId"])
            break;
        }
      }
      return timeZoneData
    }
  }
}
</script>

